import React, { useRef, useState, useContext } from "react";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

import { Button } from "@tremor/react";
import toast, { Toaster } from "react-hot-toast";

import { useSession } from "./contexts/SessionContext";


const UploadButton = ({ onFileUpload }) => {
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  const { apiFetch } = useSession();

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      setIsUploading(true);
      try {
        const uploadPromises = files.map(async (file) => {
          const response = await fetch("/api/getUploadUrl", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              filename: file.name,
              contentType: file.type,
            }),
          });
  
          if (!response.ok) {
            throw new Error("Failed to get pre-signed URL.");
          }
  
          const { url } = await response.json();
  
          const uploadResponse = await fetch(url, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": file.type,
            },
          });
  
          if (!uploadResponse.ok) {
            throw new Error("S3 Upload Error");
          }
  
          const fileKey = new URL(url).pathname.split("/").pop();
  
          return {
            file_id: fileKey,
            file_name: file.name,
            file_type: file.type,
          };
        });
  
        const uploadedFiles = await Promise.all(uploadPromises);

        if (uploadedFiles.length === 0) {
          throw new Error("No files were uploaded.");
        }
  
        // Call the process file endpoint once with all uploaded files
        const processResponse = await apiFetch({
          url: "https://cpdough--revenueai-process-files.modal.run",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            uploaded_files: uploadedFiles.map(file => ({
              file_id: file.file_id,
              file_name: file.file_name,
              file_type: file.file_type
            }))
          }
        });
  
        const processResult = processResponse.data;
        if (!processResult.files_processed) {
          throw new Error("Failed to start file processing.");
        }
  
        onFileUpload(uploadedFiles.map(file => file.file_id));
        toast.success('All files have been successfully uploaded and are being processed.');
      } catch (error) {
        console.error("Upload error:", error);
        toast.error('An error occurred during the upload. Please try again.');
      } finally {
        setIsUploading(false);
        event.target.value = "";
      }
    }
  };

  return (
    <div className="inline-flex items-center mb-2">
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
      <Button
        onClick={() => fileInputRef.current.click()}
        disabled={isUploading}
        variant="primary"
        icon={DocumentPlusIcon}
      >
        {isUploading ? "Uploading..." : "Upload Files"}
      </Button>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default UploadButton;
