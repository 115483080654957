export const job_names = [
  "Austin Creek",
  "Bethel Common",
  "Bethel Phase 1",
  "Bethel Phase 2",
  "Bethel Phase 3",
  "Bethel Phase 3 Force Main",
  "Bethel Phase 4",
  "Bethel Phase 5",
  "Braxton",
  "Brickyard Common",
  "Brickyard Common Widening",
  "Brickyard Phase 4",
  "Buffalo Reserve",
  "Cadence Meadows Common",
  "Cadence Meadows Amenity",
  "Cadence Meadows Phase 1",
  "Cadence Meadows Phase 2",
  "Cadence Meadows Phase 3",
  "Cadence Meadows Phase 4",
  "Cadence Meadows Phase 5",
  "Cayden Cove",
  "Corners South",
  "Dominion",
  "Forestville Village",
  "Forty & Page",
  "Fuquay Varina PKWY",
  "Grove 98",
  "Jasper",
  "Maison Ridge",
  "Marshburn Manor",
  "Meadowbrook",
  "Mica Ridge",
  "Mills @ AF",
  "South Lakes AR1",
  "South Lakes SF 8",
  "Stillhouse Common",
  "Stillhouse Common TOPO",
  "Stillhouse Phase 1",
  "Stillhouse Phase 2",
  "Stillhouse Phase 3",
  "Triangle Landscape",
  "West Village",
  "White Oak Daycare",
  "White Oak Townhomes",
  "Williams Grove",
  "Shop",
];

export const cost_codes = [
  "100 - Tree Protection Fence",
  "101 - Construction Entrance",
  "102 - Silt Fence / Silt Fence Outlets",
  "103 - Diversion Ditch",
  "104 - Check Dam / Wattles",
  "105 - Slope Drains",
  "106 - Misc Items / Rip Rap / Concrete Wash Out",
  "107 - Ponds - Temporary  - Install",
  "108 - Ponds - Permanent  - Install",
  "109 - Ponds - Conversions",
  "110 - Seeding",
  "111 - Maintenance",
  "112 - Street Washing",
  "113 - Matting",
  "114 - Underground Detention System",
  "199 - MISC Erosion",
  "200 - Demolition",
  "201 - Clear & Grubb Site",
  "202 - Clear & Grubb Easements",
  "203 - Pond Breach",
  "204 - Asbestos Study / Removal",
  "299 - MISC Clearing/Demo",
  "300 - Strip Topsoil",
  "301 - Cut To Fill / Stockpile",
  "302 - Borrow Pits",
  "303 - Backfill Borrow Pits",
  "304 - Cut Back For Walls",
  "305 - Haul Excess Off Site",
  "306 - Import Fill From Off Site",
  "307 - Fine Grade Pads, Shoulders, Slopes",
  "308 - Backfill Curb / Islands",
  "309 - Unclassified",
  "310 - Soil Drying / Lime / Cement / Polymer",
  "311 - Respread Topsoil",
  "312 - Rock",
  "313 - Undercut",
  "400 - Retaining Walls",
  "401 - Cast In Place Walls",
  "402 - Process Fill For Backfill",
  "403 - Fencing On Walls",
  "500 - Sewer Mains",
  "501 - Sewer Manholes (Inverts)",
  "502 - Sewer Services",
  "503 - Sewer Testing",
  "504 - Grease Trap",
  "505 - Pump Station",
  "506 - Force Main",
  "507 - Aerial Sewer",
  "508 - Bypass Sewer",
  "534 - Storm Piping / FES",
  "535 - Storm Structures / Inlet Protection",
  "536 - Storm Flushing / Camera",
  "537 - Headwalls",
  "538 - Stream Bypass",
  "539 - Box Culvert",
  "568 - Water Mains / Valves / FH / BO / Fittings",
  "569 - Water Testing",
  "570 - Water Services",
  "599 - MISC Pipe",
  "600 - Curb & Gutter",
  "601 - Sidewalks",
  "602 - HC Ramps",
  "603 - Mail Kiosk Pads",
  "604 - Drive Aprons",
  "605 - Remove/Replace Curb & Sidewalk",
  "606 - Valley Gutter",
  "607 - Concrete Parking Areas",
  "608 - Concrete Traffic Control Devices",
  "609 - Concrete Spillway",
  "610 - Dumpster Pad",
  "611 - Bike Rack Pad",
  "612 - Concrete Pad",
  "699 - MISC Concrete",
  "700 - Fine Grade Subgrade For ABC",
  "701 - ABC / Placing & Set Up",
  "702 - Adjust Catch Basins",
  "703 - Adjust Manholes & Valves",
  "704 - Geotextile Fabric",
  "799 - MISC ABC",
  "800 - Surface Course - Initial Lift 9.5",
  "801 - Surface Course - Overlay 9.5",
  "802 - Intermediate Course 19",
  "803 - Base Course 25",
  "804 - Patching Asphalt",
  "805 - Temporary Turnaround",
  "806 - Striping / Signs",
  "899 - MISC Asphalt",
  "900 - Traffic Control",
  "901 - Demolition",
  "902 - Clear & Grubb",
  "903 - Excavation / Backfill Curb / Fine Grade",
  "904 - Fine Grade Subgrade For Paving",
  "905 - Curb & Gutter",
  "906 - ABC",
  "908 - Sawcut",
  "909 - Milling",
  "910 - Asphalt - Base Course",
  "911 - Asphalt - Intermediate Course",
  "912 - Asphalt - Surface Course",
  "913 - Concrete Islands",
  "914 - Sidewalks / HC Ramps",
  "915 - Patching",
  "916 - Pavement Markings / Signs",
  "917 - Wedging",
  "918 - Repair / Maintain Existing Driveways",
  "919 - Guardrail",
  "920 - Specialty Items",
  "999 - Various Widening Supplies",
  "1000 - Fees & Permits",
  "1001 - Construction Staking / As-Builts",
  "1002 - Geotechnical Services",
  "1003 - Dry Utility Conduits",
  "1004 - Fines / Locates / Repair Damaged Utilities",
  "1005 - Bonds",
];
