// src/ChatSidebar.js
import React, { Fragment } from "react";
import { useAssistant, Message } from "ai/react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';

import { ChatInputForm } from "./PromptForm";
import { useSession } from "./contexts/SessionContext";
import { useAuth0 } from "@auth0/auth0-react";

const ChatSidebar = ({ isOpen, onClose }) => {
  const { getAccessTokenSilently } = useAuth0();

  // custom fetch with async await for access token from auth0
  const fetchWithAccessToken = async (url, options = {}) => {
    const accessToken = await getAccessTokenSilently();
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  const {
    status,
    messages,
    setMessages,
    threadId,
    input,
    submitMessage,
    handleInputChange,
    error,
  } = useAssistant({
    fetch: fetchWithAccessToken,
    api: "/api/newChat",
    //body: { assistantId: assistantId },
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-3xl z-[100]">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={onClose}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl z-25">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Chat with RevenueAI
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {messages.map((m) => (
                        <div
                          key={m.id}
                          className="flex flex-col w-full leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-xl dark:bg-gray-700 mb-4"
                        >
                          <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            {m.role === "user" ? (
                              <UserCircleIcon className="h-6 w-6 text-gray-900 dark:text-white" />
                            ) : (
                              <img
                                src="icon_transparent.png"
                                alt="Assistant Icon"
                                className="h-6 w-6"
                              />
                            )}
                          </div>
                          <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                            {m.role !== "data" && (
                              <ReactMarkdown remarkPlugins={[remarkGfm]}>{m.content}</ReactMarkdown>
                            )}
                            {m.role === "data" && (
                              <details className="group">
                                <summary className="cursor-pointer text-gray-500 hover:text-gray-700">
                                  RevenueAI is crunching the numbers...
                                </summary>
                                <pre className="text-xs bg-gray-200 p-2 mt-1 whitespace-pre-wrap">
                                  {/* {JSON.stringify(m.data, null, 2)} this is for showing the raw data of the function call */}
                                  {m.data.description}
                                </pre>
                              </details>
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="space-y-4 w-full mx-auto sm:px-4 border-t bg-background px-4 py-2 shadow-lg sm:rounded-t-xl sm:border">
                      <ChatInputForm
                        status={status}
                        input={input}
                        submitMessage={submitMessage}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ChatSidebar;
