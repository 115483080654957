import React from "react";
import { HashLink } from "react-router-hash-link";
import { Disclosure } from "@headlessui/react";
import {
  ChatBubbleLeftEllipsisIcon,
  UserCircleIcon,
  ServerIcon,
  CurrencyDollarIcon,
  ChevronUpIcon,
  CheckBadgeIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Invoice Extraction",
    description:
      "Extract key information from invoices, such as the total amount, due date, and vendor name.",
    icon: UserCircleIcon,
  },
  {
    name: "Invoice Categorization",
    description:
      "Configure custom P&L codes, project names, and more... RevenueAI will automatically assign the right categories to each invoice.",
    icon: ChatBubbleLeftEllipsisIcon,
  },
  {
    name: "Invoice Analysis",
    description:
      "Get insights into your spending, such as how much you're spending on each category, vendor, and more.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "RevenueAI Copilot",
    description:
      "Chat with RevenueAI to get insights into your invoices, such as asking for a summary of your spending this month.",
    icon: ServerIcon,
  },
];

const faqs = [
  {
    question: "How do I get started?",
    answer: (
      <span>
        1. Sign Up for RevenueAI <br />
        2. Upload your Invoices <br />
        3. RevenueAI will extract and analyze your invoice data <br />
        4. Chat with RevenueAI Copilot to ask specific questions
      </span>
    ),
  },
  {
    question: "How does RevenueAI keep my invoices private and secure?",
    answer: (
      <span>
        RevenueAI uses end-to-end encryption to keep your data secure. We only use your data to provide you with insights and analysis.
      </span>
    ),
  },
  {
    question: "How do I add my own custom categories for invoice categorization?",
    answer: (
      <span>
        Contact us at hello@revenueai.app to get started with custom categories.
      </span>
    ),
  },
  {
    question: "What AI technology does RevenueAI use?",
    answer: (
      <span>
        RevenueAI has developed custom AI agents that are trained to extract and categorize invoice data. Our AI agents use a combination of best-in-class AI models from <a href="https://www.openai.com" target="_blank" rel="noreferrer">OpenAI</a> and <a href="https://www.anthropic.com" target="_blank" rel="noreferrer">Anthropic</a>.
      </span>
    ),
  },
  {
    question: "Does RevenueAI integrate with other accounting software?",
    answer: (
      <span>
        RevenueAI is currently working on integrating with <a href="https://www.xero.com" target="_blank" rel="noreferrer">Xero</a>, <a href="https://www.quickbooks.com" target="_blank" rel="noreferrer">QuickBooks</a>, and <a href="https://www.zoho.com" target="_blank" rel="noreferrer">Zoho</a>.
        Reach out if you'd like to use RevenueAI with these or any other accounting software. 
      </span>
    ),
  },
];

function PricingCard({ title, description, price, features }) {
  return (
    <div className="flex flex-col flex-1 mx-3 my-6 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-2">
      <div className="p-8 flex flex-col h-full">
        <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
          {title}
        </div>
        <div className="mt-3">
          <span className="text-4xl font-bold">{price}</span>
          <span className="text-gray-500">/month</span>
        </div>
        <p className="mt-2 text-gray-500">{description}</p>
        <ul className="mt-4 mb-4 text-gray-600 text-left flex-grow">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center p-2">
              <CheckBadgeIcon className="h-7 w-7 text-indigo-500 mr-2 flex-shrink-0" />
              {feature}
            </li>
          ))}
        </ul>
        <div classname="flex justify-end mt-auto">
        <a
          href="/account"
          className="mt-5 px-4 py-2 text-white font-semibold bg-indigo-600 rounded hover:bg-indigo-500"
        >
          Get started
        </a>
        </div>
      </div>
    </div>
  );
}

const HomePage = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#c6dbe0] to-[#2a6f77] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-20 lg:py-22">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Process and Analyze your Invoices Faster with AI
            </h1>
            <h2 className="text-2xl mt-4 font-bold text-gray-600">
              Automate the frustrating parts of Accounts Payable by using AI to extract, categorize, and analyze your invoices.
            </h2>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/dashboard"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get Started
              </a>
              <HashLink
                smooth
                to="#how-it-works"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </HashLink>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#c6dbe0] to-[#2a6f77] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="overflow-hidden py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4" id="how-it-works">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    How it Works
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Get Started with RevenueAI
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    RevenueAI uses AI Agents to process and analyze your invoices. Here's how it works:
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <video
                src="/revenueai_demo.mp4"
                autoPlay
                loop
                muted
                playsInline
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>

        <div className="py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto text-center" id="pricing">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Pricing
              </h2>
              <div className="flex flex-col md:flex-row sm:justify-center sm:space-x-6">
                <PricingCard
                  title="Starter"
                  description="Testing and development"
                  price="$0"
                  features={[
                    "Upload your first invoice",
                    "Automatically extract invoice data",
                    "Download your invoice data as an Excel workbook",
                  ]}
                />
                <PricingCard
                  title="Pro"
                  description="For individuals and small teams"
                  price="$100"
                  features={[
                    "Upload up to 20 invoices per month",
                    "Automatically extract invoice data",
                    "Download your invoice data as an Excel workbook",
                    "RevenueAI Team will help you configure custom categories",
                  ]}
                />
                <PricingCard
                  title="Enterprise"
                  description="For businesses"
                  price="$500"
                  features={[
                    "Upload unlimited invoices",
                    "Forward invoices to RevenueAI via email",
                    "Automatically extract invoice data",
                    "Download your invoice data as an Excel workbook",
                    "RevenueAI Team will help you configure custom categories",
                    "Use our API to extract and categorize invoices",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-4 pt-16">
          <h2 className="text-3xl mb-6 text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <div className="mx-auto w-full max-w-4xl rounded-2xl bg-white p-2">
            {faqs.map((faq, index) => (
              <Disclosure
                as="div"
                className={`${index > 0 ? "mt-2" : ""}`}
                key={faq.question}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-base font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                      <span>{faq.question}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-purple-500 flex-shrink-0`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-gray-500">
                      {faq.answer}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        </div>

        <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
            <img
              className="mx-auto h-20"
              src="https://www.asterave.com/aster_logo.png"
              alt=""
            />
            <figure className="mt-10">
              <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <p>
                  RevenueAI is built by{" "}
                  <a
                    href="http://www.asterave.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Aster
                  </a>
                  , a small team that is passionate about generative AI and the
                  future of work.
                </p>
              </blockquote>
            </figure>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
