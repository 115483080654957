import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const toTitleCase = (str) => {
return str.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
});
};