import React, {createContext, useContext, useState, useCallback, useEffect, useMemo} from 'react';
import axios from 'axios';
import posthog from 'posthog-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const SessionContext = createContext();

const useApiFetch = () => {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();

  // Promise that returns a custom axios instance which includes the access token
  const customAxios = useMemo(() => {
    if(!isAuthenticated) return Promise.resolve(axios.create());
    return getAccessTokenSilently().then(accessToken => axios.create({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }), (err) => {
      console.error('Error getting access token', err);
      logout({ logoutParams: { returnTo: window.location.origin } });
      return axios.create();
    });
  }, [getAccessTokenSilently, isAuthenticated, logout]);

  // Return a fetch function which calls the axios instance once resolved
  return useCallback(
    (options) => customAxios.then(axiosInstance => axiosInstance(options)),
    [customAxios]
  );
};

export const SessionContextProvider = ({children}) => {
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const apiFetch = useCallback(async (options) => {
    if (!isAuthenticated) {
      console.error('User is not authenticated');
      logout({ returnTo: window.location.origin });
      navigate('/login');
      throw new Error('User is not authenticated');
    }

    try {
      const token = await getAccessTokenSilently({
        audience: `https://revenueai.us.auth0.com/api/v2/`,
        scope: "openid profile email offline_access",
      });
      return axios({
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error getting access token', error);
      logout({ returnTo: window.location.origin });
      throw error;
    }
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  useEffect(() => {
    if (isAuthenticated && user) {
      apiFetch({
        url: '/api/getUser',
        method: 'GET'
      }).then(response => {
        setUserInfo(response.data);

        // Initialize PostHog with user info
        posthog.identify(response.data.sub, {
          email: response.data.email,
          name: response.data.name,
          nickname: response.data.nickname,
          auth0_user_id: response.data.sub,
          given_name: response.data.given_name,
          family_name: response.data.family_name,
          role: response.data.role,
          organization_id: response.data.organization_id,
          organization_name: response.data.organization_name
        });

      }).catch(error => {
        console.error('Failed to fetch user organization info:', error);
      });
    }
  }, [user, isAuthenticated, apiFetch]);

  const initializing = isAuthenticated && !user;

  return (
    <SessionContext.Provider value={{ apiFetch, user, userInfo, initializing }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);