"use client";

import { useEffect, useRef, useState } from "react";
import Textarea from "react-textarea-autosize";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { IconSpinner } from "./ui/Icons.js";
import { Button } from "./ui/Button.js";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "./ui/Tooltip.js";
import { useEnterSubmit } from "./hooks/use-enter-submit";

export function ChatInputForm({
  status,
  input,
  submitMessage,
  handleInputChange,
}) {
  const inputRef = useRef(null);
  const { formRef, onKeyDown } = useEnterSubmit();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = () => {
    if (input.trim()) {
      submitMessage(input); // Pass input directly
    }
  };

  return (
    <TooltipProvider>
      <form
        ref={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (window.innerWidth < 600) {
            event.target.blur();
          }
          handleSubmit(); // Call handleSubmit directly
        }}
        className="relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background px-8 sm:rounded-md sm:border sm:px-12"
      >
        <Textarea
          ref={inputRef}
          className="min-h-[60px] w-full resize-none bg-transparent px-4 py-[1.3rem] focus-within:outline-none sm:text-sm border-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent"
          placeholder={`Ask RevenueAI a question...`}
          autoFocus
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
          value={input}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
        />
        <div className="absolute right-0 top-[13px] sm:right-4">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="submit"
                disabled={status !== "awaiting_message" || !input.trim()} // Check input only
                variant="outline"
                size="icon"
                className="rounded-full bg-background p-0"
              >
                {status === "in_progress" ? (
                  <IconSpinner className="w-6 h-6 animate-spin" />
                ) : (
                  <>
                    <ArrowUpCircleIcon className="w-6 h-6" />
                    <span className="sr-only">Send</span>
                  </>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>Send message</TooltipContent>
          </Tooltip>
        </div>
      </form>
    </TooltipProvider>
  );
}