import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Outlet,
} from "react-router-dom";
import {
  Auth0Provider,
  withAuthenticationRequired,
  useAuth0,
} from "@auth0/auth0-react"; // Import the Auth0 hook
import HomePage from "./HomePage";
import Header from "./Header";
import "./App.css";
import ReactGA from "react-ga4";

import Dashboard from "./Dashboard";
import FileDetail from "./FileDetail";
import ContactUs from "./ContactUs";

import posthog from "posthog-js";

import { FilesDataProvider } from "./contexts/FilesContext";
import { SessionContextProvider } from "./contexts/SessionContext";
import { SubscriptionDataProvider } from "./contexts/SubscriptionDataContext";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const AppLayout = () => {
  return (
    <SessionContextProvider>
      <FilesDataProvider>
        {/* <SubscriptionDataProvider> Stripe not set up yet */}
        <Outlet />
        {/* </SubscriptionDataProvider> */}
      </FilesDataProvider>
    </SessionContextProvider>
  );
};

export default function App() {
  useEffect(() => {
    ReactGA.initialize("G-");
    posthog.init("phc_5nqmU1d1nShHmjYBBLThNKoJGfrcs15zs8JVCGve4yg", { api_host: "https://us.i.posthog.com" });
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain="revenueai.us.auth0.com"
        clientId="0yUDvHbrNXSPeWU0Xy5OAQHAEVzcLXBt"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://revenueai.us.auth0.com/api/v2/",
          scope: "openid profile email offline_access",
        }}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <HeaderWrapper />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route element={<AppLayout />}>
            <Route
              path="/dashboard"
              element={<ProtectedRoute component={Dashboard} />}
            />
            {/* <Route path="/dashboard/:fileId" element={<ProtectedRoute component={FileDetail} />} /> */}
          </Route>
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

const HeaderWrapper = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <Header
      isAuthenticated={isAuthenticated}
      loginWithRedirect={loginWithRedirect}
    />
  );
};
